<template>
  <b-card
    v-if="loading"
    class="mb-1 mt-1 issues-loader"
  >
    <b-col class="d-flex justify-content-center">
      <b-spinner label="Loading..." />
    </b-col>
  </b-card>
  <b-card
    v-else
  >
    <b-col>
      <b-row>
        <b-col>
          <h4>
            Admins logs 
          </h4>
          <span>
            Last month records : {{ last_month_records }}
          </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <new-table
            :data-source="adminsLogsSource"
            :export-file-name="exportFileName"
            :columns="columns"
            table-name="admins-logs-list"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>
<script>
import {
  VBTooltip, BCard, BCol,
} from 'bootstrap-vue'
import Moment from 'moment'
import NewTable from '@/views/new-table-ssr.vue'
import CustomStore from 'devextreme/data/custom_store'

export default {
  components: {
    BCard,
    BCol,
    NewTable,
    CustomStore
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      adminsLogsSource: null,
      loading: false,
      moment: Moment,
      exportFileName: `admin-logs-list-${Moment(new Date()).format('YYYY-MM-DD')}`,
      admin_user: null,
      last_month_records: 0,
      adminsOptions: [],
      routes: {
        scooter: 'view-scooter',
        ride: 'view-ride',
        customer: 'view-customer',
        admin: 'admins',
        role: 'roles',
      },
      filter: '',
      logs: [],
      logsFields: [
        { key: 'causer.name', label: 'Admin' },
        { key: 'causer.email', label: 'Email' },
        { key: 'action_model', label: 'Affected' },
        { key: 'action', label: 'Action' },
        { key: 'created_date', label: 'Date' },
      ],

      columns: [
        {
          label: 'Admin',
          data: 'admin.name',
          firstnode: 'admin',
          secondnode: 'name',
        },
        {
          label: 'Email',
          data: 'admin.email',
          firstnode: 'admin',
          secondnode: 'email',
        },
        {
          label: 'Affected',
          data: 'action_model',
          custom_template: {
            template_name: 'admins.logs.model',
          },
        },
        {
          label: 'Action',
          data: 'action',
        },
        {
          label: 'Date',
          data: 'created_at',
          custom_template: {
            template_name: 'admins.logs.created_at',
          },
          calculateCellValue(data) {
            return Moment.utc(data.created_at * 1000).local().format('ll hh:mma')
          },

        },
      ],
      userEmail: '',
      adminLogsSource: null,
    }
  },
  computed: {
    filteredLogs() {
      return (this.filter?.trim() || this.admin_user)
        ? this.findInValues(this.admin_user ? this.logs.filter(log => log.causer.id === this.admin_user) : this.logs, this.filter)
        : this.logs
    },
  },
  mounted() {



    this.adminsLogsSource = new CustomStore({
      key: 'id',
      load: loadOptions => {

        let postData = {
                search_filters: loadOptions,
        }


        if (loadOptions.filter !== undefined && loadOptions.filter.length > 0) {
          this.setFilterData('admins-logs-list', loadOptions)
        } else {
          this.setFilterData('admins-logs-list', null)
        }

        return this.$http.post(this.$resources.adminsLogs, postData)
        .then(data => {
          this.last_month_records = data.data.data.last_month_records;
            return {
              data: data.data.data.rows,
              totalCount: data.data.data.pagination.total,
            }
          })
          .catch(error => { console.log(error) })
      },
    })


  },
  methods: {
    setFilter(text) {
      this.filter = text
    },
    findInValues(logs, search) {
      return logs.filter(entry => [
        entry.action,
        entry.type,
        entry.causer?.name,
        entry.causer?.email,
        Moment.utc(entry.created_at * 1000).local().format('hh:mm ll'),
      ].some(attribute => attribute?.toLowerCase().includes(search.toLowerCase())))
    },
  },
}
</script>

<style lang="scss" scoped>
  .issues-loader {
     .card-body {
       min-height: 900px;
       display: flex;
       align-items: center;
       justify-content: center;
    }
  }
</style>
